import { defineStore } from 'pinia'
import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(isBetween)

import { useAppStore, useRewardsStore, useSessionStore } from '@stores'

import { toast } from '@/js/lib/toast.js'

export const useCartStore = defineStore('cart', {
	state: () => ({
		loading: false,
		items: [],
		subtotal: 0,
		subtotalFormatted: 0,
		orderDiscountAmount: null,
	}),

	getters: {
		isCartEmpty: (state) => state.items.length === 0,
		getKit: (state) => state.items.find((item) => item.is_kit),
		itemCount: (state) => state.items.length,
		quantityTotal: (state) => {
			return state.items.reduce((acc, item) => acc + item.quantity, 0)
		},
		cartSubtotal: (state) => state.items.reduce((acc, item) => acc + item.price, 0).toFixed(2),
		ephemoralCount: (state) => (lineItemId) => {
			return state.items
				.filter((item) => lineItemId === item.line_item_id)
				.reduce((acc, curr) => acc + curr.quantity, 0)
		},
		hasItemForPaletteCreditInCart: (state) => {
			const lookup = state.items.find((item) => item.discount_id?.includes('palette_credit'))
			return !!lookup
		},
		// filteredItems: (state) => state.items.filter((item) => !item.auto_add || item.discount_id === 'loyalty'),
		filteredItems: (state) => state.items, // currently no filter - cart updates 03/17/25
		cartItemIds: (state) => state.items.map(({ details }) => details.itemId),
	},

	actions: {
		clearCart() {
			this.items = []
		},
		setCartItems(items) {
			this.items = items
		},
		setSubtotal(subtotal) {
			this.subtotal = subtotal
		},
		setSubtotalFormatted(subtotalFormatted) {
			this.subtotalFormatted = subtotalFormatted
		},
		setOrderDiscountAmount(orderDiscountAmount) {
			this.orderDiscountAmount = orderDiscountAmount
		},
		changeQuantity({ itemIndex, quantity }) {
			const items = this.items
			items[itemIndex].quantity = quantity
			this.items = items
		},
		async addToCart({ items }) {
			try {
				this.loading = true
				const appStore = useAppStore()
				const rewardStore = useRewardsStore()
				const sessionStore = useSessionStore()
				const payload = { items }
				const { data, status } = await axios.post(sessionStore.shopRoute('cart.items.store'), payload)
				const { items: cartItems, subtotal, subtotalFormatted } = data
				this.items = cartItems
				this.subtotal = subtotal
				this.subtotalFormatted = subtotalFormatted
				rewardStore.addRewardsFromCart(data)

				if (status === 200) {
					// Do nothing for now - bag panel will open
					// const message = `Successfully added ${items.length > 1 ? `${items.length} items` : 'item'} to cart!`
					// toast({ type: 'success', title: 'Success', message })
				} else if (status === 207) {
					const { errors } = data
					for (const errorMessage of errors) {
						toast({ type: 'error', title: 'Error', message: errorMessage })
					}
				}

				appStore.openBagPanel()
				this.loading = false
				return { data }
			} catch (error) {
				const message = error.response?.data.message || 'There was an error trying to add item(s) to your cart.'
				toast({ type: 'error', title: 'Error', message, timeout: 5000 })
				this.loading = false
				return { data: null }
			}
		},
		async updateItemQuantity({ lineItemId, quantity, oldQuantity }) {
			try {
				this.loading = true
				let itemIndex
				const rewardStore = useRewardsStore()
				const sessionStore = useSessionStore()
				if (quantity === oldQuantity) {
					return
				}
				itemIndex = this.items.findIndex((item) => item.line_item_id === lineItemId)
				if (itemIndex === -1) {
					return
				}
				let newQuantity = quantity
				const ephemoralCheck = this.ephemoralCount(lineItemId)
				if (ephemoralCheck !== oldQuantity) {
					const direction = quantity - oldQuantity > 0 ? 1 : -1
					newQuantity = ephemoralCheck + direction
				}
				this.changeQuantity({ itemIndex, quantity: newQuantity })

				const { data } = await axios.patch(sessionStore.shopRoute('cart.items.update', { lineItemId }), {
					quantity: newQuantity,
				})
				const { items, subtotal, subtotalFormatted } = data
				this.items = items
				this.subtotal = subtotal
				this.subtotalFormatted = subtotalFormatted
				rewardStore.addRewardsFromCart(data)
				this.loading = false
				return { data }
			} catch (error) {
				const message = error.response?.data.message || 'There was an error trying to add update your item quantity.'
				toast({ type: 'error', title: 'Error', message, timeout: 5000 })
				this.changeQuantity({ itemIndex, quantity: oldQuantity })
				this.loading = false
				throw new Error(error)
			}
		},
		async removeFromCart({ lineItemId, itemCode, isLoyalty, loyaltyRemovalEndpoint }) {
			try {
				this.loading = true
				const rewardStore = useRewardsStore()
				const sessionStore = useSessionStore()
				if (isLoyalty) {
					const { data } = await axios.post(loyaltyRemovalEndpoint, { itemCode })
					const { items, subtotal, subtotalFormatted } = data
					this.items = items
					this.subtotal = subtotal
					this.subtotalFormatted = subtotalFormatted
					rewardStore.addRewardsFromCart(data)
					this.loading = false
					return { data }
				}

				const { data } = await axios.delete(sessionStore.shopRoute('cart.items.destroy', { lineItemId }))
				const { items, subtotal, subtotalFormatted } = data
				this.items = items
				this.subtotal = subtotal
				this.subtotalFormatted = subtotalFormatted
				rewardStore.addRewardsFromCart(data)
				this.loading = false
				return { data }
			} catch (error) {
				const message = error.response?.data.message || 'There was an error trying to remove item.'
				toast({ type: 'error', title: 'Error', message, timeout: 5000 })
				this.loading = false
				throw new Error(error)
			}
		},
	},
})
