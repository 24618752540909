import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
	state: () => ({
		isScrollLocked: false,
		profilePanelOpen: false,
		marketModal: false,
		shopMenuOpen: false,
		authMenuOpen: false,
		bagPanelOpen: false,
		searchOpen: false,
		isLargeMediaQuery: false,
	}),

	getters: {},

	actions: {
		setLargeMediaQuery() {
			if (this.shopMenuOpen) {
				this.closeShopMenu()
			}
			this.isLargeMediaQuery = true
		},
		unsetLargeMediaQuery() {
			this.isLargeMediaQuery = false
		},
		disableNavHover() {
			const navClassName = '.has-nav-dropdown'
			const elements = document.querySelectorAll(navClassName)
			elements.forEach((el) => {
				el.style.pointerEvents = 'none'
			})
		},
		enableNavHover() {
			const navClassName = '.has-nav-dropdown'
			const elements = document.querySelectorAll(navClassName)
			elements.forEach((el) => {
				el.style.pointerEvents = 'auto'
			})
		},
		openMarketModal() {
			this.marketModal = true
		},
		closeMarketModal() {
			this.marketModal = false
		},
		openShopMenu() {
			this.shopMenuOpen = true
			this.lockPageScroll()
			this.hideGorgiasChat()
		},
		closeShopMenu() {
			this.shopMenuOpen = false
			this.unlockPageScroll()
			this.showGorgiasChat()
		},
		openAuthMenu() {
			this.authMenuOpen = true
			this.lockPageScroll()
		},
		closeAuthMenu() {
			this.authMenuOpen = false
			this.unlockPageScroll()
		},
		hideGorgiasChat() {
			const gorciasId = 'gorgias-chat-container'
			const el = document.getElementById(gorciasId)
			if (el) {
				el.style.display = 'none'
			}
		},
		showGorgiasChat() {
			const gorciasId = 'gorgias-chat-container'
			const el = document.getElementById(gorciasId)
			if (el) {
				el.style.display = 'block'
			}
		},
		lockPageScroll() {
			document.body.style.overflow = 'hidden'
			this.isScrollLocked = true
		},
		unlockPageScroll() {
			document.body.style.overflow = 'unset'
			this.isScrollLocked = false
		},
		openBagPanel() {
			this.lockPageScroll()
			this.bagPanelOpen = true
		},
		closeBagPanel() {
			this.unlockPageScroll()
			this.bagPanelOpen = false
		},
		openSearch() {
			this.lockPageScroll()
			this.searchOpen = true
			this.disableNavHover()
		},
		closeSearch() {
			this.unlockPageScroll()
			this.searchOpen = false
			this.enableNavHover()
		},
	},
})
