import { defineStore } from 'pinia'

import { toast } from '@/js/lib/toast.js'
import { getRewardProgress } from '@js/utils.js'

import { useSessionStore } from './session'
import { useCartStore } from './cart'

const progress = (reward) => {
	const levels = reward.levels
	const currentAmount = reward.qualifying_items_subtotal
	return getRewardProgress({ levels, currentAmount })
}

export const useRewardsStore = defineStore('rewards', {
	state: () => ({
		rewards: [],
		paletteRewards: [],
		rewardCredit: 0,
		rewardDiscounts: 0,
		discount_items_count: 0,
		coupon: null,
	}),

	getters: {
		sliderRewards: (state) => state.rewards.filter(({ display_reward_slider }) => display_reward_slider),
		topReward() {
			return this.sliderRewards.find(({ display_order }) => display_order === 1)
		},
		middleReward() {
			return this.sliderRewards.find(({ display_order }) => display_order === 2)
		},
		inlineRewards() {
			return this.sliderRewards
				.filter(({ display_order }) => display_order >= 3)
				.filter((reward) => progress(reward) !== '100%')
				.sort((a, b) => a.display_order - b.display_order)
		},
		hasRewardCredit: (state) => state.rewardCredit > 0,
		remainingDiscountItemCount: (state) => {
			const remaining = state.rewardDiscounts - state.discount_items_count
			return Math.max(remaining, 0)
		},
		paletteCreditRewardLevel: (state) => {
			const levels = state.paletteRewards?.levels
			if (levels) {
				const len = levels.length - 1
				for (let i = len; i >= 0; i--) {
					if (levels[i].qualifies) {
						return levels[i]
					}
				}
			}
			return null
		},
		hasPaletteCredit() {
			const level = this.paletteCreditRewardLevel
			if (!level) return false
			return level.credit > 0
		},
		paletteCreditModalMessage() {
			const level = this.paletteCreditRewardLevel
			if (!level) return null
			return `You can add a palette to your bag to use your ${level.credit_formatted} Palette Credit.`
		},
		orderLevelDiscount: (state) => {
			const APPLICABLE_IDS = ['loyalty']

			const discountRewards = state.rewards.filter(
				(reward) => APPLICABLE_IDS.includes(reward.reward_id) && reward.discount_type === 'dollar'
			)

			// Note: scary and needs to be careful if we do coupons for this other than dollar

			if (discountRewards.length === 0) return null

			const discounts = []
			let hasReward = false
			for (const discountReward of discountRewards) {
				let achieved = null
				for (const level of discountReward.levels) {
					if (level.qualifies) {
						achieved = level
					}
				}
				if (achieved) {
					hasReward = true
					discounts.push({
						...achieved,
						original: discountReward.qualifying_items_subtotal,
						title: discountReward.title,
						discountType: discountReward.discount_type,
					})
				}
			}

			if (hasReward) {
				// currently focusing on one 'id' for loyalty
				// if we add more before refactoring this mess
				// need to accumulate these
				return {
					discount: discounts[0].credit_formatted,
					message: 'Coupon Applied',
				}
			}
			return null
		},
		hasRewards: (state) => state.rewards.length > 0,
		getRewardById: (state) => (id) => {
			return state.rewards.find((obj) => obj.reward_id === id)
		},
	},

	actions: {
		addRewardsFromCart({ rewards }) {
			if (rewards) {
				this.setRewards(rewards)
			}
		},
		async toggleDiscount({ discountCode, action }) {
			let response
			const isAdd = action === 'add'

			const session = useSessionStore()
			try {
				if (isAdd) {
					response = await axios.post(session.checkoutShopRoute('discounts.store'), { discountCode })
				} else {
					response = await axios.delete(session.checkoutShopRoute('discounts.remove'))
				}
			} catch (error) {
				throw error
			}

			const { data } = response
			const orderDiscountAmount = data.discount.discount_amount_formatted_type ?? null
			const cart = useCartStore()
			cart.setOrderDiscountAmount(orderDiscountAmount)
			cart.setCartItems(data.cart.items)
			cart.setSubtotal(data.cart.subtotal)
			this.rewards = data.rewards

			return response
		},
		setRewards(rewards) {
			this.rewards = rewards
			let foundCoupon = null
			for (const reward of rewards) {
				if (reward.reward_id === 'palette_credit' || reward.reward_id === 'double_palette_credit') {
					this.paletteRewards = reward
				} else if (reward.reward_id === 'coupon') {
					foundCoupon = reward
				}
			}
			this.coupon = foundCoupon
		},
		async addLoyaltyCoupon(itemCode) {
			try {
				const { data } = await axios.post(route('checkout.loyalty.claim', { itemCode }))
				const { success } = data
				if (success) {
					toast({ type: 'success', title: 'Success', message: 'Coupon applied!' })
					window.location.reload()
				}
			} catch (error) {
				toast({
					type: 'error',
					title: 'Error',
					message: error.response?.data?.message ?? 'An error occured. Please try again.',
					timeout: 5000,
				})
			}
		},
		async removeLoyaltyCoupon(itemCode) {
			try {
				const { data } = await axios.post(route('checkout.loyalty.remove'), { itemCode })
				const { success } = data
				if (success) {
					toast({ type: 'success', title: 'Success', message: 'Coupon removed!' })
					window.location.reload()
				}
			} catch (error) {
				toast({
					type: 'error',
					title: 'Error',
					message: error.response?.data?.message ?? 'An error occured. Please try again.',
					timeout: 5000,
				})
			}
		},
	},
})
