export const findLastIndex = (arr, predicate) => {
	const reversedArray = [...arr].reverse()
	const index = reversedArray.findIndex(predicate)

	if (index === -1) {
		return -1
	}

	return arr.length - 1 - index
}
export const debounce = (func, wait) => {
	let timeout
	return function (...args) {
		clearTimeout(timeout)
		timeout = setTimeout(() => {
			func.apply(this, args)
		}, wait)
	}
}
export const isObject = (variable) => {
	if (typeof variable === 'object') {
		return !Array.isArray(variable)
	}
	return false
}
export const isArray = (variable) => {
	if (typeof variable === 'object') {
		return Array.isArray(variable)
	}

	return false
}
const HEX_BRIGHTNESS_THRESHOLD = 245
export const isBright = (color) => {
	const hex = color.replace('#', '')
	const c_r = parseInt(hex.substr(0, 2), 16)
	const c_g = parseInt(hex.substr(2, 2), 16)
	const c_b = parseInt(hex.substr(4, 2), 16)
	const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
	return brightness > HEX_BRIGHTNESS_THRESHOLD
}
export const getPaletteCreditRewardProgress = ({ levels, currentAmount }) => {
	const len = levels.length
	if (levels[len - 1].qualifies) return '100%'

	const progressPerHalfColumn = 100 / (len * 2)
	let progress = 0

	for (let i = 0; i < len; i++) {
		const level = levels[i]
		const previousAmount = i > 0 ? levels[i - 1].qualifying_amount : 0

		if (level.qualifies) {
			progress += progressPerHalfColumn * 2
		} else if (currentAmount > previousAmount) {
			const levelRange = level.qualifying_amount - previousAmount
			const spendWithinLevel = currentAmount - previousAmount
			const partialProgress = (spendWithinLevel / levelRange) * progressPerHalfColumn

			progress += partialProgress
			break
		}
	}

	return Math.min(progress, 100).toFixed(2) + '%'
}
export const getRewardProgress = ({ levels, currentAmount }) => {
	const numLevels = levels.length
	const percentPerLevel = 100 / numLevels
	const sumArr = new Array(numLevels).fill(0)
	for (const [idx, level] of levels.entries()) {
		const { qualifies, qualifying_amount } = level
		if (qualifies) {
			sumArr[idx] = percentPerLevel
		} else {
			let lastQualifiedAmt
			if (idx === 0) {
				lastQualifiedAmt = 0
			} else {
				lastQualifiedAmt = levels[idx - 1].qualifying_amount
			}
			const levelGoal = qualifying_amount - lastQualifiedAmt
			const levelProgress = currentAmount - lastQualifiedAmt
			const levelPercent = levelProgress / levelGoal
			const combinedPercent = percentPerLevel * levelPercent
			sumArr[idx] = parseFloat(combinedPercent.toFixed(2))
			// no need to proceed
			break
		}
	}
	const mySum = (total, num) => total + num
	const computedProgress = sumArr.reduce(mySum)
	return `${computedProgress}%`
}
