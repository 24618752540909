<template>
	<div class="site-wrapper h-full min-h-screen flex flex-col justify-between">
		<slot name="main" />
		<slot name="cookie-banner" />
		<slot name="footer" />
	</div>
</template>
<script setup>
import { onMounted, onUnmounted, nextTick } from 'vue'

import { useAppStore, useCartStore, useRewardsStore, useSessionStore } from '@stores'

const props = defineProps({
	cart: {
		type: Object,
		required: true,
	},
	session: {
		type: Object,
		required: true,
	},
	rewards: {
		type: Object,
		required: true,
	},
	hideCart: {
		type: Boolean,
		default: false,
	},
})

const appStore = useAppStore()
const { setLargeMediaQuery, unsetLargeMediaQuery } = appStore
const cartStore = useCartStore()
const { setCartItems, setSubtotal, setSubtotalFormatted, setOrderDiscountAmount } = cartStore
const sessionStore = useSessionStore()
const {
	setLoggedIn,
	setCurrencyCode,
	setCurrencySymbol,
	setCountryCode,
	setWarehouseId,
	setLanguageCode,
	setLanguageId,
	setPriceTypeId,
	setShoppingContext,
	setIsEnrollContext,
	setIsRetailContext,
	setCheckoutUrl,
	setIsWholesaleCustomer,
	setWebAlias,
	setWebAliasArtistName,
	setWebAliasArtistId,
	setTrackingWebAlias,
	setTrackingArtistName,
	setTrackingWebUrl,
	setClearTrackingWebUrl,
	setItemSearchKey,
	setModalsFired,
} = sessionStore
const rewardStore = useRewardsStore()
const { setRewards } = rewardStore

onMounted(() => {
	window.addEventListener('resize', resizeHandler, { passive: true })
	nextTick(() => resizeHandler())
})
onUnmounted(() => window.removeEventListener('resize', resizeHandler))

const resizeHandler = () => {
	if (window.matchMedia('(min-width: 1024px)').matches) {
		setLargeMediaQuery()
	} else {
		unsetLargeMediaQuery()
	}
}
const commitRewards = () => {
	if (!props.rewards) return
	setRewards(props.rewards)
}
const commitCart = () => {
	if (!props.cart) return
	const items = props.cart.items || []
	const subtotal = props.cart.subtotal || 0
	const subtotalFormatted = props.cart.subtotalFormatted || 0
	const orderDiscountAmount =
		props.cart.rewards.orderDiscountAmount > 0 ? props.cart.rewards.orderDiscountAmountFormatted : null
	setCartItems(items)
	setSubtotal(subtotal)
	setSubtotalFormatted(subtotalFormatted)
	setOrderDiscountAmount(orderDiscountAmount)
}
const commitSession = () => {
	if (!props.session) return
	setLoggedIn(props.session.logged_in || false)
	setCurrencyCode(props.session.currency_code || null)
	setCurrencySymbol(props.session.currency_symbol || null)
	setCountryCode(props.session.country_code || null)
	setWarehouseId(props.session.warehouse_id || null)
	setLanguageCode(props.session.language_code || null)
	setLanguageId(props.session.language_id || null)
	setPriceTypeId(props.session.price_type_id || null)
	setShoppingContext(props.session.shopping_context || null)
	setIsEnrollContext(!!props.session.is_enroll_context || false)
	setIsRetailContext(!!props.session.is_retail_context || false)
	setCheckoutUrl(props.session.checkout_url || [])
	setIsWholesaleCustomer(!!props.session.is_wholesale_customer || false)
	setWebAlias(props.session.web_alias || null)
	setWebAliasArtistName(props.session.web_alias_artist_name || null)
	setWebAliasArtistId(props.session.web_alias_artist_id || null)
	setTrackingWebAlias(props.session.tracking_web_alias || null)
	setTrackingArtistName(props.session.tracking_artist_name || null)
	setTrackingWebUrl(props.session.tracking_web_url || null)
	setClearTrackingWebUrl(props.session.clear_tracking_web_url || null)
	setItemSearchKey(props.session.item_search_index || null)
	const storagedIds = JSON.parse(localStorage.getItem('modal_ids'))
	setModalsFired(storagedIds ?? [])
}
// note: placing hydration functions here - same as created() in vuejs 2
commitRewards()
commitCart()
commitSession()
</script>
